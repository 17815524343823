import { Component, OnInit, Input, Output, EventEmitter, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { StepService } from '../services/step.service';
import { LanaService } from '../services/lana.service';
import { ExerciseService } from '../services/exercise.service';
import ThreeMeshUI from 'three-mesh-ui';


@Component({
  selector: 'app-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.css']
})


export class StepComponent implements OnInit {
  @Input() step: any;
  @Input() exercise: any;
  @Output() updateExercise = new EventEmitter<boolean>();
  @Output() updateExerciseSteps = new EventEmitter<boolean>();
  model: string;
  myExercise: any;
  isColaborative: boolean = false;
  users: any[] = [];

  constructor(public stepService: StepService, public lanaService: LanaService, public exerciseService: ExerciseService) { }


  ngOnInit() {

    this.exerciseService.getByExerciseId(this.exercise.exerciseId).subscribe(
      exercise => {
        this.myExercise = exercise[0];
        if (this.myExercise.type) {
          this.isColaborative = this.myExercise.type === "C";
          if (this.myExercise.users) {
            for (var i = 1; i <= this.myExercise.users; i++) {
              const option = {
                value: i
              };
              this.users.push(option);
            }
          }
        }
        else {
          this.isColaborative = false;
        }
      }
    );

  }

  ngOnChanges() {
    const container = new ThreeMeshUI.Block({
      height: 1.5,
      width: 1
    });
  }


  public onModel(myModel: string) {
    this.model = myModel;
  }

  update() {
    this.exercise.getSteps(this.exercise.exerciseId);
    this.exercise.activeIndex = this.exercise.activeIndex - 1;
    if (this.exercise.activeIndex < 0) {
      this.exercise.activeIndex = 0;
    }
    this.exercise.ngOnInit();
  }

  onModelChanged($event) {
    this.saveMatrixTransforms(this.step.initialModels);
    this.saveMatrixTransforms(this.step.correctModels);
    this.saveMatrixTransforms(this.step.incorrectModels);
    this.stepService.editStep(this.step);
    //update next steps
    this.stepService.getNumberSteps(this.step.exerciseId).subscribe(
      nsteps => {
        let count = nsteps.count;
        if (this.step.order < count - 1) {
          this.stepService.updateNextSteps(this.step.exerciseId, this.step.order, this.step.initialModels, this.step.correctModels, this.emitUpdateExerciseSteps, this);
        }
        else {
          this.updateExerciseSteps.emit(true);
        }
      });
  }

  emitUpdateExerciseSteps(self) {
    self.updateExerciseSteps.emit(true);
  }

  onStepDeleted($event) {
    this.updateExercise.emit(true);
  }

  saveMatrixTransforms(models) {
    models.forEach(element => {
      element.matrixWorld = this.lanaService.getMatrixWorld(element.name);
    });

  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from "@angular/forms";

import { Teacher } from "../models/Teacher";
import { Exercise } from "../models/Exercise";

import { AuthService } from '../services/auth.service';
import { ExerciseService } from '../services/exercise.service';
import { ClassroomService } from '../services/classroom.service';
import { SpinnerService } from "../services/spinner.service";

import { SelectItem } from 'primeng/api';
import { TranslateService } from "@ngx-translate/core";

//import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-exercise-list',
  templateUrl: './exercise-list.component.html',
  styleUrls: ['./exercise-list.component.css']
})
export class ExerciseListComponent implements OnInit {
  names: string[];
  exerciseTypes: any[] = [];
  exerciseType: any;
  nUsers: any[] = [];
  users: any;
  nClassrooms: any[] = [];
  classrooms: any;

  teacher: Teacher;
  myExercises: any;
  key: string;
  name: string;

  MAX_USERS: number = 4;
  MAX_CLASS: number = 5;


  sub: any;
  displayNewExerciseDialog: boolean = false;
  exercise: Exercise = new Exercise();

  selectedOwner: string[] = ['Mine'];
  public: boolean = false;
  author: boolean = false;

  exerciseform: FormGroup;

  sortOptions: SelectItem[];
  sortKey: string;
  sortField: string;
  sortOrder: number;

  constructor(private authService: AuthService,
    private exerciseService: ExerciseService,
    private classroomService: ClassroomService,
    private spinnerService: SpinnerService,
    private fb: FormBuilder,
    private translate: TranslateService) {
    this.names = [];
    this.exerciseTypes = [
      { name: this.translate.instant("Individual"), value: "I" },
      { name: this.translate.instant("Demostrative"), value: "D" },
      { name: this.translate.instant("Colaborative"), value: "C" },
    ];

    for (var i = 1; i <= this.MAX_USERS; i++) {
      const option = {
        value: i
      };
      this.nUsers.push(option);
    }

    for (var i = 1; i <= this.MAX_CLASS; i++) {
      const option = {
        value: i
      };
      this.nClassrooms.push(option);
    }


  }

  paginate(event) { }

  ngOnInit() {
    this.exerciseform = this.fb.group({
      'exerciseType': new FormControl('', Validators.required),
        'users': new FormControl('', Validators.required),
        'classrooms': new FormControl('', Validators.required),
        'title': new FormControl('', Validators.required),
        'description': new FormControl('', Validators.required),
        'keyword': new FormControl([], Validators.required),
        'isPublic': new FormControl(false),
    });

    this.authService.getLogged().subscribe(
      teacher => {
        this.teacher = teacher;
        this.loadProjects();
      }
    );

    this.sortOptions = [{
      label: this.translate.instant('ModifiedDateNewFirst'), value: 'newest'
    }, { label: this.translate.instant('ModifiedDateOldFirst'), value: 'oldest' },
    { label: this.translate.instant('Title'), value: 'title' },
    { label: this.translate.instant('Public'), value: 'public' },
    { label: this.translate.instant('Private'), value: 'private' }/*,
    { label: this.translate.instant('Author'), value: 'author' }*/
    ];

  }

  showNewExerciseDialog() {
    this.displayNewExerciseDialog = true;
  }

  onExerciseTypeChange(event){
    if(this.exerciseType.value === 'C'){
        this.exerciseform.controls['users'].enable();
      this.exerciseform.controls['classrooms'].enable();
    }
    else{
      this.exerciseform.controls['users'].disable();
      this.exerciseform.controls['classrooms'].disable();
    }
  }

  onSortChange(event) {
    let value = event.value;
    if (this.myExercises.length > 0) {
      if (value === "public") {
        this.myExercises.sort(function (a, b) { return (b.isPublic - a.isPublic) });
      }
      else if (value === "private") {
        this.myExercises.sort(function (a, b) {
          return (a.isPublic - b.isPublic)
        });
      }
      else if (value === "title") {
        this.myExercises.sort(function (a, b) {
          let titleA = a.title.toLowerCase();
          let titleB = b.title.toLowerCase();
          if (titleA > titleB) return 1;
          else return -1;
        });
      }
      else if (value === "newest") {
        this.myExercises.sort(function (a, b) {
          let dateA = a.modified
          let dateB = b.modified;
          if (dateA < dateB) return 1;
          else return -1;
        });
      }
      else if (value === "oldest") {
        this.myExercises.sort(function (a, b) {
          let dateA = a.modified
          let dateB = b.modified;
          if (dateA > dateB) return 1;
          else return -1;
        });
      }




    }
  }

  onSubmit() {
    let value = this.exerciseform.value;
    this.newExercise(value);
  }

  cancel() {
    this.displayNewExerciseDialog = false;
  }



  newExercise(value: any) {
    this.spinnerService.displayLoader(true);
    let data: any = [];
    data.type = value.exerciseType.value;
    data.users = this.isColaborative() ? value.users.value : 0;
    data.classrooms = this.isColaborative() ? value.classrooms.value : 0;
    data.title = value.title;
    data.description = value.description;
    data.keyword = value.keyword;
    data.isPublic = value.isPublic;
    this.exerciseService.newExercise(data, this.teacher.id, this.refreshList, this).then(
      res => {
        //create classrooms if colaborative
        if (data.type === "C") {
          for (let i = 0; i < data.classrooms; i++) {
            let myroom: any = [];
            myroom.name = "Classroom " + i;
            myroom.numUsers = data.users;
            myroom.exerciseId = res['exercise'].id;
            this.classroomService.newClassroom(myroom);
          }
        }
      }
    );

    this.displayNewExerciseDialog = false;
  }

  refreshList(self) {
    self.loadProjects();
  }

  onExerciseChanged($event) {
    this.loadProjects();
  }

  findByKeywordName(key: string, name: string) {
    this.myExercises = [];
    if (key && !name) {
      this.exerciseService.findByKeyword(key).subscribe(
        exercises => {
          this.myExercises = exercises;
        });
    }
    else if (!key && name) {
      this.exerciseService.findByName(name).subscribe(
        exercises => {
          this.myExercises = exercises;
        });
    }
    else {
      this.exerciseService.findByKeywordName(key, name).subscribe(
        exercises => {
          this.myExercises = exercises;
        });
    }
  }


  loadProjects() {
    //search projects where teacher.id is editor
    this.myExercises = [];
    if (this.teacher) {
      this.exerciseService.getAllFromAuthor(this.teacher.id).subscribe(
        exercises => {
          this.myExercises = exercises;
        });
    }
  }

  searchPublic() {
    this.myExercises = [];
    //search all public exercises
    this.exerciseService.getAllPublicExercises().subscribe(
      exercises => {
        this.myExercises = exercises;
      });
    //}
  }

  isColaborative() {
    if (this.exerciseType)
      return this.exerciseType.value === "C";
  }


}

import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ISubscription } from "rxjs/Subscription";
import { StepComponent } from '../step/step.component';
import { Observable } from 'rxjs';
import $ from "jquery";
import { MessageService } from 'primeng/api';

import { LanaService } from '../services/lana.service';

import { TranslateService } from "@ngx-translate/core";

declare var LANA3D: any;
declare var VIEWER: any;

@Component({
  selector: 'app-lana',
  templateUrl: './lana.component.html',
  styleUrls: ['./lana.component.css'],
  //encapsulation: ViewEncapsulation.Native
  encapsulation: ViewEncapsulation.ShadowDom
})
export class LanaComponent implements OnInit {

  @Output() modelChanged = new EventEmitter<boolean>();

  editor: any;
  scene: any;
  mesh: any;
  myModel$: Observable<string>;
  model: string = "";
  visor: any;
  moveChecked: boolean = true;
  rotateChecked: boolean = false;
  selectedObject: boolean = false;
  movedObject: boolean = false;
  myObject: any;
  object: any;
  prevX: any = 0;
  prevY: any = 0;

  private subscription: ISubscription;


  @ViewChild('lanaContainer', { static: true }) lanaContainer: ElementRef;

  constructor(private lanaService: LanaService, private messageService: MessageService, private translate: TranslateService) { }

  ngOnInit() {

    this.subscription = this.lanaService.getEditor().subscribe(editor => {
      if (editor) {
        this.editor = editor;
        this.lanaContainer.nativeElement.appendChild(this.editor.getDomElement());
        this.editor.resize();
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngAfterViewInit() {
    $("#viewport").dblclick(function (event) {
      event.preventDefault();
    });
  }

  dblclickLana(event){
    if(event.button === 0){
        this.object = this.editor.getObjectToMove(event.clientX, event.clientY);
        if (this.object !== undefined && this.object !== null) {
          this.myObject = this.object;
          this.selectedObject = true;
        }
        else {
          this.selectedObject = false;
          return;
        }
   }
  }
  clickLana(event) {
    if(event.button === 0){
        let object = this.editor.getObject(event.clientX, event.clientY);
        if (object!== null){
        }
   }
   else{
    this.editor.unselectObject();
    this.selectedObject = false;
    this.object = null;
    }
  }

  unclickLana(event) {
    if (this.movedObject) {
      this.modelChanged.emit(true);
    }

  }
  onWheel(event){
    this.editor.unselectObject();
    this.selectedObject = false;
    this.object = null;
  }

  onMouseMove(event) {
    if (this.selectedObject) {
      this.movedObject = true;
    }
  }

  handleChangeMove(e) {
    this.moveChecked = e.checked;
    if (this.moveChecked) {
      this.rotateChecked = false;
      this.editor.setControlMode('translate');
    }
    else {
      this.rotateChecked = true;
      this.editor.setControlMode('rotate');
    }
  }

  handleChangeRotate(e) {
    this.rotateChecked = e.checked;
    if (this.rotateChecked) {
      this.moveChecked = false;
      this.editor.setControlMode('rotate');
    }
    else {
      this.moveChecked = true;
      this.editor.setControlMode('translate');
    }
  }

}

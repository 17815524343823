import { Injectable } from "@angular/core";
//import { Http, Headers, Response, RequestOptions } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs/Observable";
import { map } from "rxjs/operators/map";
import { catchError } from "rxjs/operators/catchError";
import { BehaviorSubject } from 'rxjs';
import { environment } from "../../environments/environment";
import { Step } from "../models/Step";
import { RestService } from "./rest.service";

import { StoreService } from './store.service';

import { throwError } from 'rxjs';

@Injectable()
export class StepService {
  private BASE_URL: string = environment.apiEndpoint + '/api/steps/';
  private step: BehaviorSubject<Step> = new BehaviorSubject<Step>(null);
  private step$: Observable<Step> = this.step.asObservable();

  constructor(private http: HttpClient) {
  }

  getAllFromExercise(exerciseId: string) {
    let query = "?filter={" + '\u0022' + "where" + '\u0022' + ":{" + '\u0022' + "exerciseId" + '\u0022' + ":" + '\u0022' + exerciseId + '\u0022' + "}}";
    //let query = "?filter={" + '\u0022' + "exerciseId" + '\u0022' + ":" + '\u0022' + exerciseId + '\u0022' + "}";
    return this.http.get(this.BASE_URL + query, { withCredentials: true })
      .pipe(
        map((res: any) => {
          //return res.json();
          return res;
        }),
        catchError((error: any) => {
          console.log(error);
          //return Observable.throw(error || "Server error");
          return throwError(error);
        })
      );
  }

  getStepById(stepId) {
    let query = "?filter={" + '\u0022' + "where" + '\u0022' + ":{" + '\u0022' + "id" + '\u0022' + ":" + '\u0022' + stepId + '\u0022' + "}}";
    //let query = "?filter={" + '\u0022' + "exerciseId" + '\u0022' + ":" + '\u0022' + exerciseId + '\u0022' + "}";
    return this.http.get(this.BASE_URL + query, { withCredentials: true })
      .pipe(
        map((res: any) => {
          //return res.json();
          return res;
        }),
        catchError((error: any) => {
          console.log(error);
          return Observable.throw(error || "Server error");
        })
      );
  }

  getNumberSteps(exerciseId: string) {
    let query = "count?where[exerciseId]=" + exerciseId
    return this.http.get(this.BASE_URL + query, { withCredentials: true })
      .pipe(
        map((res: any) => {
          //return res.json();
          return res;
        }),
        catchError((error: any) => {
          console.log("Error: ", error);
          return Observable.throw(error || "Server error");
        })
      );
  }


  deleteStep(stepId: string) {
    return this.http
      .delete(
        this.BASE_URL + stepId
      ).toPromise()
      .then(res => {
        //<any[]>res.json().data
        res;
      })
      .then(data => {
        return data;
      });
  }

  updateNextSteps(exerciseId: string, order: number, initialModels, correctModels, callback, param) {
    //renumber the next steps:
    //var step;
    this.getAllFromExercise(exerciseId).subscribe(
      steps => {
        let mySteps = steps;
        mySteps.sort(function (a, b) { return (a.order - b.order) });
        //update this step
        let thisStep = mySteps[order];
        mySteps[order].initialModels = initialModels;
        mySteps[order].correctModels = correctModels;
        this.editStep(mySteps[order]);

        //update next steps
        for (var i = order + 1; i < mySteps.length; i++) {
          let prevStep = mySteps[i - 1];
          let nextStep = mySteps[i];
          let models: any[] = [];
          models = nextStep.initialModels;
          for (let prevModel of prevStep.initialModels) {
            let isPrev = false;
            //console.log("model", prevModel);
            for (let j = 0; j < models.length; j++) {
              if (models[j].name === prevModel.name) {
                models[j].matrixWorld = prevModel.matrixWorld;
                isPrev = true;
              }
              if (!isPrev && j === models.length - 1) { models.push(prevModel); }
            }



          }
          for (let model of prevStep.correctModels) {
            //Estoy añadiendo otra vez todos los modelos correctos... e igual ya lo he metido. Comprobar que no está ya
            //models.push(model);
            let isPrev = false;
            for (let j = 0; j < models.length; j++) {
              if (models[j].name === model.name) {
                models[j].matrixWorld = model.matrixWorld;
                isPrev = true;
              }
              if (!isPrev && j === models.length - 1) { models.push(model); }
            }
          }
          mySteps[i].initialModels = models;
          this.editStep(mySteps[i]);

          if (i === mySteps.length - 1) {
            callback(param);
          }

          /*if (step.order > order) {
            //console.log("reedito: ", step);
            step.order = step.order - 1;
            this.editStep(step);
          }*/

        }
      });

  }

  renumberSteps(exerciseId: string, order: number, callback: any, self: any) {
    //renumber the next steps:
    //var step;
    this.getAllFromExercise(exerciseId).subscribe(
      steps => {
        for (var i = 0; i < steps.length; i++) {
          let step = steps[i];
          if (step.order > order) {
            //console.log("reedito: ", step);
            step.order = step.order - 1;
            this.editStep(step);
          }
          //emit stepDeleted
          if (i === steps.length - 1) {
            callback(self);
          }
        }
      });

  }

  renumberStepsAdd(exerciseId: string, order: number, callback: any, self: any, newStep: any) {
    //renumber the next steps:
    //var step;
    this.getAllFromExercise(exerciseId).subscribe(
      steps => {
        for (let i = 0; i < steps.length; i++) {
          let step = steps[i];
          if (step.order >= order) {
            step.order = step.order + 1;
            this.editStep(step);
          }
          //callback == createNewStep
          if (i === steps.length - 1) {
            callback(self, newStep);
          }

        }
      });

  }

  newStep(resource: any) {
    const newService = 'newStep';
    return this.http
      .post(
        this.BASE_URL + 'newStep',
        {
          title: resource.title,
          description: resource.description,
          help: resource.help,
          order: resource.order,
          activeUsers: resource.activeUsers,
          initialModels: resource.initialModels,
          correctModels: resource.correctModels,
          incorrectModels: resource.incorrectModels,
          helpFiles: resource.helpFiles,
          interactiveElements: resource.interactiveElements,
          exerciseId: resource.exerciseId,
        }
      ).pipe(
        map((response: any) => {
          // login successful if there's a accessToken token in the response
          //let step = response.json();
          let step = response;
          return step;
        })
      );
  }

  editStep(resource: any) {
    return this.http
      .put(
        this.BASE_URL,
        {
          title: resource.title,
          description: resource.description,
          help: resource.help,
          order: resource.order,
          activeUsers: resource.activeUsers,
          id: resource.id,
          initialModels: resource.initialModels,
          correctModels: resource.correctModels,
          incorrectModels: resource.incorrectModels,
          helpFiles: resource.helpFiles,
          interactiveElements: resource.interactiveElements,
          exerciseId: resource.exerciseId
        }
      ).toPromise()
      .then(res => {
        //<any[]>res.json().data
        //console.log(res);
        res;
      })
      .then(data => {
        //console.log("data; ", data);
        return data;
      });
  }

}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ConfirmationService } from 'primeng/api';

import { ExerciseService } from '../services/exercise.service';
import { LanaService } from '../services/lana.service';

import { ExerciseListComponent } from '../exercise-list/exercise-list.component'
import { Router } from '@angular/router';

import { Exercise } from "../models/Exercise";
import { TranslateService } from "@ngx-translate/core";

import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from "@angular/forms";

@Component({
  selector: 'app-exercise-info',
  templateUrl: './exercise-info.component.html',
  styleUrls: ['./exercise-info.component.css']
})
export class ExerciseInfoComponent implements OnInit {
  @Input() exercise: Exercise;
  @Output() exerciseChanged: EventEmitter<boolean> = new EventEmitter();

  displayEditExerciseDialog: boolean = false;
  displayMoodleInfoDialog: boolean = false;

  exerciseform: FormGroup;


  constructor(private confirmationService: ConfirmationService,
    private exerciseService: ExerciseService,
    private lanaService: LanaService,
    private router: Router,
    private fb: FormBuilder,
    private translate: TranslateService) { }

  ngOnInit() {
    this.exerciseform = this.fb.group({
      'title': new FormControl(this.exercise.title, Validators.required),
      'description': new FormControl(this.exercise.description, Validators.required),
      'keyword': new FormControl(this.exercise.keyword, Validators.required),
      'isPublic': new FormControl(this.exercise.isPublic),
    });
    if (this.exercise.type === undefined || this.exercise.type === null) {
      this.exercise.type = "I";
      this.editExerciseInfo(this.exercise);
    }
  }

  edit() {
    //console.log('edit');
  }

  showEditExerciseDialog() {
    this.displayEditExerciseDialog = true;
  }

  showMoodleInfoDialog() {
    this.displayMoodleInfoDialog = true;
  }

  onSubmit() {
    let value = this.exerciseform.value;
    let exerciseEdited = this.exercise;
    exerciseEdited.title = value.title;
    exerciseEdited.description = value.description;
    exerciseEdited.isPublic = value.isPublic;
    exerciseEdited.keyword = value.keyword;
    this.editExerciseInfo(exerciseEdited);
  }


  confirm(exerciseId: string) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete this exercise?',
      accept: () => {
        // Actual logic to perform a confirmation
        this.delete(exerciseId);
      }
    });
  }

  delete(exerciseId: string) {
    this.exerciseService.deleteExercise(exerciseId, this.refreshExerciseList, this);
  }

  refreshExerciseList(self: any) {
    self.exerciseChanged.emit(true);

  }

  editExerciseInfo(value: any) {
    this.exerciseService.editExercise(value);
    this.displayEditExerciseDialog = false;
  }

  goToExercise(exerciseId: string) {
    this.lanaService.resetScene(function (p) { }, this);
    this.router.navigate(["exercise", exerciseId]);
  }

  cancel() {
    this.displayEditExerciseDialog = false;
  }

  close() {
    this.displayMoodleInfoDialog = false;
  }

  isIndividual() {
    if (this.exercise.type === undefined || this.exercise.type === null || this.exercise.type === "I") {
      return true;
    }
    else return false;
  }

  isColaborative() {
    return this.exercise.type === "C";
  }
}

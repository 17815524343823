export class Step {
  id: string = null;
  title: string = null;
  description: string = null;
  help: string = null;
  order: number = null;
  activeUsers: number = null;
  initialModels: any[] = [];
  correctModels: any[] = [];
  incorrectModels: any[] = [];
  helpFiles: any[] = [];
  interactiveElements: any[] = [];
  exerciseId: string = null;

}

const emptyStep: Step = new Step();

export { emptyStep };

import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA  } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClient } from "@angular/common/http";

import { RouterModule, Routes } from '@angular/router';
import { routes } from './app.routes';

// PrimeNG Imports
/*import {
  ToolbarModule,
  ButtonModule,
  PanelModule,
  SplitButtonModule,
  FieldsetModule,
  InputTextareaModule,
  PaginatorModule,
  CheckboxModule,
  DataListModule,
  ConfirmDialogModule,
  ProgressSpinnerModule,
  DialogModule,
  ChipsModule,
  FileUploadModule,
  DataTableModule,
  GrowlModule,
  MessagesModule,
  MessageModule,
  CardModule,
  TooltipModule,
  InputSwitchModule
} from 'primeng/primeng';*/

import { ToolbarModule } from 'primeng/toolbar';
import { ButtonModule } from 'primeng/button';
import { PanelModule } from 'primeng/panel';
import { SplitButtonModule } from 'primeng/splitbutton';
import { FieldsetModule } from 'primeng/fieldset';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { PaginatorModule } from 'primeng/paginator';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DialogModule } from 'primeng/dialog';
import { ChipsModule } from 'primeng/chips';
import { FileUploadModule } from 'primeng/fileupload';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { CardModule } from 'primeng/card';
import { TooltipModule } from 'primeng/tooltip';
import { InputSwitchModule } from 'primeng/inputswitch';
import { EditorModule } from 'primeng/editor';
//import { PrimeIcons } from 'primeng/api';

import { OverlayPanelModule } from 'primeng/overlaypanel';
import { StepsModule } from 'primeng/steps';
import { AccordionModule } from 'primeng/accordion';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { DataViewModule } from 'primeng/dataview';
import { ToastModule } from 'primeng/toast';
import { TabViewModule } from 'primeng/tabview';
import { ConfirmationService } from 'primeng/api';
//import { ConfirmationService } from 'primeng/components/common/api';
import { MessageService } from 'primeng/api';
import { ListboxModule } from 'primeng/listbox';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FileSelectDirective } from 'ng2-file-upload';

import { AppComponent } from './app.component';
import { StepMenuComponent } from './step-menu/step-menu.component';
import { LanaComponent } from './lana/lana.component';

import { HttpClientModule } from '@angular/common/http';
import { ExerciseComponent } from './exercise/exercise.component';
import { FsmComponent } from './fsm/fsm.component';
import { ExerciseInfoComponent } from './exercise-info/exercise-info.component';
import { ExerciseListComponent } from './exercise-list/exercise-list.component';
import { LoginComponent } from './login/login.component';
import { StepComponent } from './step/step.component';

import { ClipboardModule } from 'ngx-clipboard';
import { PdfViewerModule } from 'ng2-pdf-viewer';


// providers
import { AUTH_PROVIDERS } from './services/auth.service';
import { StoreService } from './services/store.service';
import { UserService } from './services/user.service';
import { SpinnerService } from './services/spinner.service';
import { ExerciseService } from './services/exercise.service';
import { ClassroomService } from './services/classroom.service';
import { StepService } from './services/step.service';
import { LanaService } from './services/lana.service';

//translate
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    StepMenuComponent,
    LanaComponent,
    ExerciseComponent,
    FsmComponent,
    ExerciseInfoComponent,
    ExerciseListComponent,
    LoginComponent,
    StepComponent
  ],
  imports: [
    AccordionModule,
    BrowserModule,
    BrowserAnimationsModule,
    ToolbarModule,
    ButtonModule,
    ClipboardModule,
    HttpClientModule,
    PanelModule,
    SplitButtonModule,
    PaginatorModule,
    CheckboxModule,
    //DataListModule,
    ConfirmDialogModule,
    ProgressSpinnerModule,
    TabViewModule,
    DialogModule,
    FormsModule,
    ListboxModule,
    ReactiveFormsModule,
    ChipsModule,
    FileUploadModule,
    //DataTableModule,
    ScrollPanelModule,
    ToastModule,
    TooltipModule,
    //GrowlModule,
    MessagesModule,
    MessageModule,
    CardModule,
    DataViewModule,
    StepsModule,
    InputSwitchModule,
    OverlayPanelModule,
    PdfViewerModule,
    EditorModule,
    RouterModule.forRoot(routes, {
      useHash: true,
      relativeLinkResolution: 'legacy'
    }),
    InputTextareaModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    AUTH_PROVIDERS,
    MessageService,
    StoreService,
    SpinnerService,
    UserService,
    ConfirmationService,
    ExerciseService,
    ClassroomService,
    StepService,
    LanaService
  ],
  bootstrap: [AppComponent],
  //schemas: [NO_ERRORS_SCHEMA]
})
export class AppModule { }

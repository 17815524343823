export class Exercise {
  id: string = null;
  type: string = null;
  title: string = null;
  description: string = null;
  keyword: string[] = [];
  isPublic: boolean = false;
  author: string = null;
  owner: string = null;
  editors: string[] = [];
  created: Date = null;
  modified: Date = null;

}

const emptyExercise: Exercise = new Exercise();

export { emptyExercise };

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
//import { Http, Headers, Response } from '@angular/http';
//import { MessageService } from '../services/message.service';
import { MessageService } from 'primeng/api';
//import { StepComponent } from '../step/step.component';

import { StepService } from '../services/step.service';
import { ExerciseService } from '../services/exercise.service';
import { LanaService } from '../services/lana.service';
import { Step } from '../models/Step';
import { environment } from "./../../environments/environment";

import { Router } from '@angular/router';

import { Observable } from 'rxjs/Observable';

import { ConfirmationService } from "primeng/api";

import { Message } from 'primeng/api';
//import { $ } from 'protractor';
import * as $ from 'jquery';

import { FileUploader, FileSelectDirective } from 'ng2-file-upload/ng2-file-upload';
import { all } from 'q';
//import { text } from '@angular/core/src/render3/instructions';
import { TranslateService } from "@ngx-translate/core";
//import { forEach } from '@angular/router/src/utils/collection';
//import { runInThisContext } from 'vm';

//const URL = 'http://localhost:3000/api/upload';

@Component({
  selector: 'app-step-menu',
  templateUrl: './step-menu.component.html',
  styleUrls: ['./step-menu.component.css']
})
export class StepMenuComponent implements OnInit {

  //@Input() stepComponent: StepComponent;
  @Input() step: Step;
  @Input() colaborative: boolean;
  @Input() users: any[];

  //@Output() public model = new EventEmitter<string>();
  @Output() stepDeleted = new EventEmitter<boolean>();

  environment: any = environment;

  description: string;
  title: string;
  help: string;
  order: number;
  activeUsers: any
  id: string;
  addTexture: boolean = false;
  addModel: boolean = false;
  loading: boolean = false;
  nToLoad: number = 0;
  isInitial: boolean = false;
  nloaded: number = 0;
  daeModel: string;
  value: number = 0;


  selectedFile: string;
  //uploadedFiles: any[] = [];

  initialModels: any[] = [];
  correctModels: any[] = [];
  incorrectModels: any[] = [];
  helpFiles: any[] = [];
  interactiveElements: any[] = [];

  uploadedFileInfo: any;
  uploadedDAE: any;
  textureList: any[] = [];
  texture: string = "";

  showModel: boolean = true;



  exerciseId: string;

  msgs: Message[] = [];

  constructor(private http: HttpClient,
    private messageService: MessageService,
    public stepService: StepService,
    public exerciseService: ExerciseService,
    private lanaService: LanaService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private translate: TranslateService
  ) {
  }

  ngOnInit() {
    //this.lanaService.resetScene(function (p) { }, this);
    this.lanaService.prepareScene(function (p) { }, this);
    this.exerciseId = this.step.exerciseId;
  }

  ngOnChanges(changes) {
    if (changes.step) {
      if (changes.step.firstChange) {
      }
      else {
        this.title = this.step.title;
        this.description = this.step.description;
        this.exerciseId = this.step.exerciseId;
        this.help = this.step.help;
        this.order = this.step.order;
        this.activeUsers = { value: this.step.activeUsers };
        this.id = this.step.id;

        this.initialModels = this.step.initialModels;
        this.correctModels = this.step.correctModels;
        this.incorrectModels = this.step.incorrectModels;
        this.helpFiles = this.step.helpFiles;
        this.interactiveElements = this.step.interactiveElements;
        this.nToLoad = 0;
        if (this.helpFiles === undefined) { this.helpFiles = [] }
        if (this.interactiveElements === undefined) { this.interactiveElements = [] }

        if (this.initialModels.length > 0 || this.correctModels.length > 0) {
          //this.lanaService.resetScene(this.loadModels, this);
          this.lanaService.prepareScene(this.loadModels, this);
        }
        else {
          //this.lanaService.resetScene(function (p) { }, this);
          this.lanaService.prepareScene(function (p) { }, this);
        }
      }
    }
  }

  onTitleChange(event) {
    this.step.title = this.title;
    this.stepService.editStep(this.step);
  }

  onDescriptionChange(event) {
    this.step.description = this.description;
    this.stepService.editStep(this.step);
  }

  onHelpChange(event) {
    this.step.help = this.help;
    this.stepService.editStep(this.step);
  }


  onCorrectUpload(event) {
    this.isInitial = false;
    this.uploadedFileInfo = event.originalEvent.body;
    this.showModel = true;
    this.uploadedFileInfo.url = environment.apiEndpoint + this.uploadedFileInfo.url;
    this.correctModels.push(this.uploadedFileInfo);
    let selected = this.uploadedFileInfo.name;
    this.daeModel = selected.substring(0, selected.indexOf(".")) + ".dae";
    this.addModel = true;

    this.getInteractiveElements(this.uploadedFileInfo.url);

    this.step.initialModels = this.initialModels;
    this.step.correctModels = this.correctModels;
    this.stepService.editStep(this.step);

    this.stepService.getNumberSteps(this.exerciseId).subscribe(
      nsteps => {
        let count = nsteps.count;
        if (this.order < count - 1) {
          //Actualize next steps
          this.stepService.updateNextSteps(this.exerciseId, this.order, this.initialModels, this.correctModels, function () { }, this);
        }
      });
  }

  onIncorrectUpload(event) {
    this.isInitial = false;
    this.uploadedFileInfo = event.originalEvent.body;
    this.showModel = false;
    this.uploadedFileInfo.url = environment.apiEndpoint + this.uploadedFileInfo.url;
    this.incorrectModels.push(this.uploadedFileInfo);
    let selected = this.uploadedFileInfo.name;
    this.daeModel = selected.substring(0, selected.indexOf(".")) + ".dae";
    this.addModel = true;

    //save step
    this.step.incorrectModels = this.incorrectModels;
    this.stepService.editStep(this.step);

  }

  onHelpUpload(event) {
    this.uploadedFileInfo = event.originalEvent.body;
    this.uploadedFileInfo.url = environment.apiEndpoint + this.uploadedFileInfo.url;
    this.helpFiles.push(this.uploadedFileInfo);

    this.step.helpFiles = this.helpFiles;
    this.stepService.editStep(this.step);

  }

  onActiveUsersChange(event) {
    this.step.activeUsers = event.value.value;
    this.stepService.editStep(this.step);
  }

  onInterctiveElementTextAddAction(event, element) {
    element.action = event;
    this.step.interactiveElements = this.interactiveElements;
    this.stepService.editStep(this.step);
  }

  onInterctiveElementAddAction(event, element) {
    this.uploadedFileInfo = event.originalEvent.body;
    this.uploadedFileInfo.url = environment.apiEndpoint + this.uploadedFileInfo.url;
    element.action = this.uploadedFileInfo;

    this.step.interactiveElements = this.interactiveElements;
    this.stepService.editStep(this.step);

  }

  onInterctiveElementDeleteAction(element) {
    var index = this.interactiveElements.indexOf(element);
    if (index > -1) {
      this.interactiveElements[index].action = "";
      this.step.interactiveElements = this.interactiveElements;
      this.stepService.editStep(this.step);
    }
  }

  onInitialUpload(event) {
    this.isInitial = true;
    this.uploadedFileInfo = event.originalEvent.body;
    this.showModel = true;
    this.uploadedFileInfo.url = environment.apiEndpoint + this.uploadedFileInfo.url;
    //let matrix = { "elements": { 0: 1, 1: 1, 2: 1, 3: 1, 4: 1, 5: 1, 6: 1, 7: 1, 8: 1, 9: 1, 10: 1, 11: 1, 12: 1, 13: 1, 14: 1, 15: 1 } }
    //this.uploadedFileInfo.matrixWorld = matrix;
    this.initialModels.push(this.uploadedFileInfo);
    let selected = this.uploadedFileInfo.name;
    this.daeModel = selected.substring(0, selected.indexOf(".")) + ".dae";
    this.addModel = true;

    this.getInteractiveElements(this.uploadedFileInfo.url);

    //this.saveMatrixTransforms(this.initialModels);
    //this.saveMatrixTransforms(this.correctModels);
    this.step.initialModels = this.initialModels;
    this.step.correctModels = this.correctModels;
    this.stepService.editStep(this.step);

    this.stepService.getNumberSteps(this.exerciseId).subscribe(
      nsteps => {
        let count = nsteps.count;
        if (this.order < count - 1) {
          this.stepService.updateNextSteps(this.exerciseId, this.order, this.initialModels, this.correctModels, function () { }, this);
        }
      });
  }

  onUploadDAE(event) {
    this.addModel = false;
    this.uploadedDAE = event.originalEvent.body;
    this.uploadedDAE.url = environment.apiEndpoint + this.uploadedDAE.url;
    var mydaeObj = this.getDAEInfo(this.uploadedDAE.url);
    var parser = new DOMParser();
    var xmlDoc = parser.parseFromString(mydaeObj, "text/xml");
    var library_images = xmlDoc.getElementsByTagName("library_images")[0];
    if (library_images !== undefined) {
      var x = library_images.getElementsByTagName("image")[0];
      for (var i = 0; i < library_images.childElementCount; i++) {
        var image = library_images.getElementsByTagName("init_from")[i];
        let img = image.childNodes[0].nodeValue;
        this.textureList[i] = img.substring(img.indexOf('/') + 1);
      }
    }
    //var image = x.getElementsByTagName("init_from")[0];
    /*for (let i of image.childNodes) {
      this.textureList[i] = image.childNodes[i].nodeValue;
    }*/
    //this.texture = image.childNodes[0].nodeValue;
    //this.texture = this.texture.substring(this.texture.indexOf('/') + 1);
    if (this.textureList.length > 0) {
      this.addTexture = true;
    }
    else {
      let params = {
        this: this,
        nModel: 1,
        numModels: 1
      }
      this.loading = true;
      //let matrix = { "elements": { 0: 1, 1: 1, 2: 1, 3: 1, 4: 1, 5: 1, 6: 1, 7: 1, 8: 1, 9: 1, 10: 1, 11: 1, 12: 1, 13: 1, 14: 1, 15: 1 } }
      let matrix = { "elements": { 0: 1, 1: 0, 2: 0, 3: 0, 4: 0, 5: 1, 6: 0, 7: 0, 8: 0, 9: 0, 10: 1, 11: 0, 12: 0, 13: 0, 14: 0, 15: 1 } };
      this.nToLoad = this.nToLoad + 1;
      this.lanaService.addModel(this.uploadedDAE.url, matrix, this.updateProgressBar, this, this.isInitial);
      //this.saveMatrixTransforms(this.initialModels);
      //this.saveMatrixTransforms(this.correctModels);
    }
  }

  onInitialSelect(event) {
    return false;
  }

  onUploadTexture(event, i) {
    var index = this.textureList.indexOf(i);
    if (index > -1) {
      this.textureList.splice(index, 1);
    }

    if (this.textureList.length <= 0) {
      this.addTexture = false;
      //cargar cuando no hay más texturas para subir
      let params = {
        this: this,
        nModel: 1,
        numModels: 1
      }
      this.loading = true;
      //let matrix = { "elements": { 0: 1, 1: 1, 2: 1, 3: 1, 4: 1, 5: 1, 6: 1, 7: 1, 8: 1, 9: 1, 10: 1, 11: 1, 12: 1, 13: 1, 14: 1, 15: 1 } }
      let matrix = { "elements": { 0: 1, 1: 0, 2: 0, 3: 0, 4: 0, 5: 1, 6: 0, 7: 0, 8: 0, 9: 0, 10: 1, 11: 0, 12: 0, 13: 0, 14: 0, 15: 1 } };
      this.nToLoad = this.nToLoad + 1;
      this.lanaService.addModel(this.uploadedDAE.url, matrix, this.updateProgressBar, this, this.isInitial);
    }
    this.texture = "";
  }


  cancelAddTexture() {
    this.addTexture = false;
  }

  cancelAddDAE() {
    this.addModel = false;
  }

  hasInitialModels() {
    if (this.initialModels.length > 0) {
      return true;
    }
  }

  hasInteractiveElements() {
    if (this.interactiveElements.length > 0) {
      return true;
    }
  }

  hasCorrectModels() {
    if (this.correctModels.length > 0) {
      return true;
    }
  }

  hasIncorrectModels() {
    if (this.incorrectModels.length > 0) {
      return true;
    }
  }

  hasHelpFiles() {
    if (this.helpFiles.length > 0) {
      return true;
    }
  }

  save() {
    let newStep: Step;
    newStep = this.step;
    newStep.title = this.title;
    newStep.description = this.description;
    newStep.exerciseId = this.exerciseId;
    newStep.help = this.help;
    newStep.initialModels = this.initialModels;
    newStep.correctModels = this.correctModels;
    newStep.incorrectModels = this.incorrectModels;
    newStep.helpFiles = this.helpFiles;
    newStep.interactiveElements = this.interactiveElements;
    newStep.id = this.id;
    this.stepService.editStep(newStep);

    //To save update Date
    this.exerciseService.getByExerciseId(this.exerciseId).subscribe(
      exercise => {
        let myExercise = exercise[0];
        this.exerciseService.editExercise(myExercise);
      }
    );


    let summary = this.translate.instant('Saved');
    let detail = this.translate.instant('Correctly_Saved');
    this.messageService.add({ severity: 'success', summary: summary, detail: detail });
    //this.showInfo(summary, detail);
  }

  saveMatrixTransforms(models) {
    models.forEach(element => {
      element.matrixWorld = this.lanaService.getMatrixWorld(element.name);
      //Maybe the if it is not needed
      if (element.matrixWorld === undefined, element.matrixWorld === null) {
        //let matrix = { "elements": { 0: 1, 1: 1, 2: 1, 3: 1, 4: 1, 5: 1, 6: 1, 7: 1, 8: 1, 9: 1, 10: 1, 11: 1, 12: 1, 13: 1, 14: 1, 15: 1 } }
        let matrix = { "elements": { 0: 1, 1: 0, 2: 0, 3: 0, 4: 0, 5: 1, 6: 0, 7: 0, 8: 0, 9: 0, 10: 1, 11: 0, 12: 0, 13: 0, 14: 0, 15: 1 } };
        element.matrixWorld = matrix;
      }

    });
    this.stepService.editStep(this.step);
  }


  confirmDelete() {
    this.confirmationService.confirm({
      message: this.translate.instant('Are_You_Sure'),
      accept: () => {
        //Actual logic to perform a confirmation
        this.delete();
      }
    });
  }

  delete() {
    let stepId = this.step.id;
    let stepOrder = this.step.order;
    this.stepService.deleteStep(stepId);

    //TODO: Modificar orden de los pasos posteriores:
    this.stepService.renumberSteps(this.exerciseId, this.order, this.reloadExerciseSteps, this);
    let summary = this.translate.instant('Deleted');
    let detail = this.translate.instant('Correctly_Deleted');
    this.messageService.add({ severity: 'success', summary: summary, detail: detail });
  }

  reloadExerciseSteps(self: any) {
    self.stepDeleted.emit(true);
  }

  deleteInitialModel(model) {
    var index = this.initialModels.indexOf(model);
    if (index > -1) {
      this.initialModels.splice(index, 1);
      let mydaeObj = model.url;
      mydaeObj = mydaeObj.substring(0, mydaeObj.indexOf(".viwork")) + ".dae";
      this.lanaService.deleteModel(mydaeObj);
      this.step.initialModels = this.initialModels;
      this.stepService.editStep(this.step);
      this.deleteInteractiveElements(model.url);
      this.stepService.getNumberSteps(this.exerciseId).subscribe(
        nsteps => {
          let count = nsteps.count;
          if (this.order < count - 1) {
            this.stepService.updateNextSteps(this.exerciseId, this.order, this.initialModels, this.correctModels, function () { }, this);
          }
        });
    }
  }

  deleteCorrectModel(model) {
    var index = this.correctModels.indexOf(model);
    if (index > -1) {
      this.correctModels.splice(index, 1);
      let mydaeObj = model.url;
      mydaeObj = mydaeObj.substring(0, mydaeObj.indexOf(".viwork")) + ".dae";
      this.lanaService.deleteModel(mydaeObj);
      this.step.correctModels = this.correctModels;
      this.stepService.editStep(this.step);
      this.deleteInteractiveElements(model.url);
      this.stepService.getNumberSteps(this.exerciseId).subscribe(
        nsteps => {
          let count = nsteps.count;
          if (this.order < count - 1) {
            this.stepService.updateNextSteps(this.exerciseId, this.order, this.initialModels, this.correctModels, function () { }, this);
          }
        });
    }
  }

  deleteIncorrectModel(model) {
    var index = this.incorrectModels.indexOf(model);
    if (index > -1) {
      this.incorrectModels.splice(index, 1);
      let mydaeObj = model.url;
      mydaeObj = mydaeObj.substring(0, mydaeObj.indexOf(".viwork")) + ".dae";
      this.lanaService.deleteModel(mydaeObj);
      this.step.incorrectModels = this.incorrectModels;
      this.stepService.editStep(this.step);
      this.deleteInteractiveElements(model.url);
    }
  }

  deleteHelpFiles(file) {
    var index = this.helpFiles.indexOf(file);
    if (index > -1) {
      this.helpFiles.splice(index, 1);
      this.step.helpFiles = this.helpFiles;
      this.stepService.editStep(this.step);
    }
  }

  removeCorrectModels(){
    if (this.step.correctModels.length > 0) {
      for (let model of this.step.correctModels) {
        let mydaeObj = model.url;
        mydaeObj = mydaeObj.substring(0, mydaeObj.indexOf(".viwork")) + ".dae";
        console.log("deleting correct model... ", mydaeObj);
        this.lanaService.deleteModel(mydaeObj);
      }
    }
  }

  removeIncorrectModels(){
    if (this.step.incorrectModels.length > 0) {
      for (let model of this.step.incorrectModels) {
        let mydaeObj = model.url;
        mydaeObj = mydaeObj.substring(0, mydaeObj.indexOf(".viwork")) + ".dae";
        console.log("deleting incorrect model... ", mydaeObj);
        this.lanaService.deleteModel(mydaeObj);
      }
    }
  }

  getJson(url) {
    return JSON.parse($.ajax({
      type: 'GET',
      url: url,
      dataType: 'json',
      global: false,
      async: false,
      success: function (data) {
        return data;
      }
    }).responseText);
  }

  getDAEInfo(url) {
    return $.ajax({
      type: 'GET',
      url: url,
      dataType: 'json',
      global: false,
      async: false,
      success: function (data) {
        return data;
      }
    }).responseText;
  }

  loadModels(self: any) {

    let numModels = self.initialModels.length + self.correctModels.length;
    let sum = 100 / numModels;
    let nModel = 1;
    let matrix;

    if (self.initialModels.length > 0) {
      console.log("loading initial models ....");
      self.nToLoad = self.nToLoad + self.initialModels.length;
      for (let initialModel of self.initialModels) {
        self.loading = true;
        let mydaeObj = initialModel.url;
        mydaeObj = mydaeObj.substring(0, mydaeObj.indexOf(".viwork")) + ".dae";
        if (initialModel.matrixWorld === undefined || initialModel.matrixWorld === null) {
          //matrix = { "elements": { 0: 1, 1: 1, 2: 1, 3: 1, 4: 1, 5: 1, 6: 1, 7: 1, 8: 1, 9: 1, 10: 1, 11: 1, 12: 1, 13: 1, 14: 1, 15: 1 } };
          matrix = { "elements": { 0: 1, 1: 0, 2: 0, 3: 0, 4: 0, 5: 1, 6: 0, 7: 0, 8: 0, 9: 0, 10: 1, 11: 0, 12: 0, 13: 0, 14: 0, 15: 1 } };
        }
        else {
          matrix = initialModel.matrixWorld;
        }
        let rename = mydaeObj.substring(mydaeObj.indexOf("/api/"));
        mydaeObj = environment.apiEndpoint + rename;
        self.lanaService.addModel(mydaeObj, matrix, self.updateLoadProgressBar, self, true);
        self.value = self.value + sum;
        nModel = nModel + 1;
      }
    }
    if (self.correctModels.length > 0) {
      console.log("loading correct models ....");
      self.nToLoad = self.nToLoad + self.correctModels.length;
      for (let correctModel of self.correctModels) {
        self.loading = true;
        let mydaeObj = correctModel.url;
        mydaeObj = mydaeObj.substring(0, mydaeObj.indexOf(".viwork")) + ".dae";
        if (correctModel.matrixWorld === undefined || correctModel.matrixWorld === null) {
          //matrix = { "elements": { 0: 1, 1: 1, 2: 1, 3: 1, 4: 1, 5: 1, 6: 1, 7: 1, 8: 1, 9: 1, 10: 1, 11: 1, 12: 1, 13: 1, 14: 1, 15: 1 } }
           matrix = { "elements": { 0: 1, 1: 0, 2: 0, 3: 0, 4: 0, 5: 1, 6: 0, 7: 0, 8: 0, 9: 0, 10: 1, 11: 0, 12: 0, 13: 0, 14: 0, 15: 1 } };
        }
        else {
          matrix = correctModel.matrixWorld;
        }
        //let matrix = { "elements": { 0: 1, 1: 1, 2: 1, 3: 1, 4: 1, 5: 1, 6: 1, 7: 1, 8: 1, 9: 1, 10: 1, 11: 1, 12: 1, 13: 1, 14: 1, 15: 1 } }
        let rename = mydaeObj.substring(mydaeObj.indexOf("/api/"));
        mydaeObj = environment.apiEndpoint + rename;
        self.lanaService.addModel(mydaeObj, matrix, self.updateLoadProgressBar, self, false);
        self.value = self.value + sum;
      }
    }
    //load incorrect models
    if (self.incorrectModels.length > 0) {
      console.log("loading incorrect models ....");
      self.nToLoad = self.nToLoad + self.incorrectModels.length;
      for (let incorrectModel of self.incorrectModels) {
        self.loading = true;
        let mydaeObj = incorrectModel.url;
        mydaeObj = mydaeObj.substring(0, mydaeObj.indexOf(".viwork")) + ".dae";
        if (incorrectModel.matrixWorld === undefined || incorrectModel.matrixWorld === null) {
          matrix = { "elements": { 0: 1, 1: 0, 2: 0, 3: 0, 4: 0, 5: 1, 6: 0, 7: 0, 8: 0, 9: 0, 10: 1, 11: 0, 12: 0, 13: 0, 14: 0, 15: 1 } };
        }
        else {
          matrix = incorrectModel.matrixWorld;
        }
        let rename = mydaeObj.substring(mydaeObj.indexOf("/api/"));
        mydaeObj = environment.apiEndpoint + rename;
        self.lanaService.addModel(mydaeObj, matrix, self.updateLoadProgressBar, self, false);
        self.value = self.value + sum;
      }
    }
  }

  updateProgressBar(params) {
    params.saveMatrixTransforms(params.initialModels);
    params.saveMatrixTransforms(params.correctModels);
    if (params.nToLoad === 0) {
      params.loading = false;
    }

  }

  updateLoadProgressBar(params) {
    if (params.nToLoad === 0) {
      params.loading = false;
    }
  }

  getInteractiveElements(viWorkFile) {
    let jsonInfo = this.getJson(viWorkFile);
    let nodes = jsonInfo.nodes;
    for (let node in nodes) {
      if (nodes[node].type === "sound" || nodes[node].type === "text" || nodes[node].type === "file") {
        let iElement: any = {};
        iElement.name = nodes[node].name;
        iElement.type = nodes[node].type;
        iElement.action = "";
        this.interactiveElements.push(iElement);
      }
    }
    this.step.interactiveElements = this.interactiveElements;
  }

  deleteInteractiveElements(viWorkFile) {
    let jsonInfo = this.getJson(viWorkFile);
    let nodes = jsonInfo.nodes;
    let modelInteractiveElem = [];
    for (let node in nodes) {
      if (nodes[node].type === "sound" || nodes[node].type === "text" || nodes[node].type === "file") {
        this.interactiveElements.forEach(element => {
          if (element.name === nodes[node].name) {
            var index = this.interactiveElements.indexOf(element);
            if (index > -1) {
              this.interactiveElements.splice(index, 1);
              this.step.interactiveElements = this.interactiveElements;
              this.stepService.editStep(this.step);
            }
          }
        });
      }
    }
  }
}


import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

//declare var LANA3D: any;
declare var THREE: any;
declare var VIEWER: any;

@Injectable()
export class LanaService {
  editor: any;
  scene: any;

  private myModel: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private myModel$: Observable<string> = this.myModel.asObservable();
  private clearScene: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private clearScene$: Observable<boolean> = this.clearScene.asObservable();

  private myScene: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private myScene$: Observable<any> = this.myScene.asObservable();

  private myEditor: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private myEditor$: Observable<any> = this.myScene.asObservable();


  constructor() {
    this.editor = VIEWER.Editor.create();
    //this.scene = LANA3D.NodeManager.createNode("scene");
    //this.scene = new THREE.Scene()
    //this.editor.setScene(this.scene);

  }

  /*addModel(model: string) {
    console.log("lanaService:", model);
    this.myModel.next(model);

  }*/

  addModel(model: string, matrix, callback, params, isInitial) {
    let files = [];
    files.push(model);
    this.editor.loadModelFromFile(files, matrix, callback, params, isInitial);
  }

  deleteModel(model: string) {
    //console.log("model: ", model);
    let name = model.substring(model.lastIndexOf("/") + 1);
    //console.log("name: ", name);
    let scene = this.editor.getScene();
    //console.log("scene: ", scene);
    //let sceneNode = scene.getSceneNode();
    //let nodo = scene.findChildrenByName(name, true);
    let nodo = scene.getObjectByName(name, true);
    if (nodo) {
      this.editor.deleteNode(nodo);
    }
  }

  getModel() {
    return this.myModel.asObservable();
  }

  resetScene(callback, self) {
    this.editor.deleteScene(callback, self);
    //this.editor = VIEWER.Editor.create();
    this.myEditor.next(this.editor);
    //this.editor.deleteScene();
    //this.myEditor.next(this.editor);
  }

  prepareScene(callback, self){
    let scene = this.editor.getScene();
    if(scene.children.length == 0){
      this.resetScene(callback, self)
    }
    else{
      callback(self);
      console.log("scene is ready");
    }
  }

  setControlMode(mode) {
    this.editor.setControlMode(mode);
  }

  getResetScene() {
    return this.clearScene.asObservable();
  }

  getScene() {
    return this.myScene.asObservable();
  }

  getEditor() {
    return this.myEditor.asObservable();
  }

  getMatrixWorld(elementName) {
    elementName = elementName.replace(".viwork", ".dae");
    return this.editor.getMatrixWorld(elementName);
  }

  setMatrixWorld(elementName, matrix) {
    elementName = elementName.replace(".viwork", ".dae");
    this.editor.setMatrixWorld(elementName, matrix);
  }

  setVRMode() {
    //let polyfill = new WebVRPolyfill();
    let vrMode = this.editor.setVRMode(true);
    return vrMode;
  }

  addUI() {
    this.editor.setUIButtons();

  }

  addUIInfo(title, description) {
    this.editor.setUIInfo(title, description);
  }

  addUIText(text) {
    this.editor.setUIText(text);
  }

  deleteUIInfo() {
    this.editor.deleteUIInfo();
  }

  getIntersectedObject(showDragger) {
    return this.editor.getIntersectedObject(showDragger);
  }

  deleteIntersectedObject() {
    this.editor.deleteIntersectedObject();
  }

  addContainer() {
    this.editor.showContainer();
  }

  removeContainer() {
    this.editor.removeContainer();
  }

  moveObjToX(obj, val) {
    this.editor.moveObjToX(obj, val);
  }

  moveObjToY(obj, val) {
    this.editor.moveObjToY(obj, val);
  }

  moveObjToZ(obj, val) {
    this.editor.moveObjToZ(obj, val);
  }

  rotateObjX(obj, val) {
    this.editor.rotateObjX(obj, val);
  }

  rotateObjY(obj, val) {
    this.editor.rotateObjY(obj, val);
  }

  rotateObjZ(obj, val) {
    this.editor.rotateObjZ(obj, val);
  }
  /*sendMessage(message: Message): void {
    this.subject.next(message);
  }

  getMessage(): Observable<Message> {
    return this.subject.asObservable();
  }*/
}

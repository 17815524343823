import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs/Observable";
import { BehaviorSubject } from 'rxjs';
import { environment } from "../../environments/environment";
import { Classroom } from "../models/Classroom";
import { map } from "rxjs/operators/map";
import { catchError } from "rxjs/operators/catchError";

import * as moment from "moment";

@Injectable()
export class ClassroomService {
  private BASE_URL: string = environment.apiEndpoint + '/api/classrooms/';
  private classroom: BehaviorSubject<Classroom> = new BehaviorSubject<Classroom>(null);
  private classroom$: Observable<Classroom> = this.classroom.asObservable();

  constructor(private http: HttpClient) {
  }

  newClassroom(resource: any) {
    return this.http
      .post(
        this.BASE_URL + 'newClassroom',
        {
          name: resource.name,
          numUsers: resource.numUsers,
          exerciseId: resource.exerciseId,
        }
      ).toPromise()
      .then(res => {
        console.log("res classroom: ", res);
        return res;
      })
      .then(data => {
        console.log("data classroom: ", data);
        return data;
      });
  }

  getUsersFromClassroomId(classroomId: string) {
    let query = "?filter={" + '\u0022' + "where" + '\u0022' + ":{" + '\u0022' + "id" + '\u0022' + ":" + '\u0022' + classroomId + '\u0022' + "}}";
    return this.http.get(this.BASE_URL + query, { withCredentials: true })
      .pipe(
        map((res: any) => {
          console.log('res; ', res);
          return res;
        }),
        catchError((error: any) => {
          console.log(error);
          return Observable.throw(error || "Server error");
        })
      );
  }

  editClassroom(resource: any) {
    let modified = moment();
    return this.http
      .put(
        this.BASE_URL,
        {
          name: resource.name,
          numUsers: resource.numUsers,
          classrooms: resource.classrooms,
          title: resource.title,
          description: resource.description,
          keyword: resource.keyword,
          isPublic: resource.isPublic,
          authorId: resource.authorId,
          ownerId: resource.ownerId,
          editors: resource.editors,
          created: resource.created,
          id: resource.id,
          modified: modified
        }
      ).toPromise()
      .then(res => {
        //console.log("res: ", res);
        //<any[]>res.json().data;
        console.log();
        res;
      })
      .then(data => {
        console.log("return data: ", data);
        return data;
      });
  }

}

import { Injectable } from '@angular/core';
//import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
// import { Category } from '../models/Category';
import { RestService } from './rest.service';
import { map, tap } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';

import { Teacher, emptyTeacher } from '../models/Teacher';

@Injectable()
export class UserService extends RestService<any> {
  private BASE_URL: string = environment.apiEndpoint + '/api/teacher/';
  //private TOKEN_NAME: string = environment.tokenName;

  constructor(http: HttpClient) {
    super(`${environment.apiEndpoint}/api/teacher`, http);
  }

  getAllFromCompany(companyId): Observable<any[]> {
    return this.http
      .get(
        `${environment.apiEndpoint}/api/teacher/findB` +
        this.getAccessToken() +
        `&companyId=${companyId}`
      )
      //.pipe(map((res: Response) => <any[]>res.json().users));
      //JSON.parse(response)
      .pipe(map((res: any) => <any[]>res));
  }

  create(idMoodle: string): Observable<any> {
    //console.log('creo el usuario');
    /*if (resource.id) {
      delete resource.id;
    }*/
    //const userService = 'teacher';
    return this.http
      .post(
        this.BASE_URL,
        { idMoodle: idMoodle }

      )
      .pipe(
        map((response: Response) => {
          return true;
        })
      );
    /*return super.create(resource).pipe(
      tap(x => {
        console.log(x);
        //this.updateRoles(x.id, resource.roles).subscribe();
      })
    );*/
  }

  update(resource: any): Observable<any> {
    return super.update(resource).pipe(
      tap(x => {
        //console.log(x);
        //this.updateRoles(x.id, resource.roles).subscribe();
      })
    );
  }
  /*updateRoles(userId: string, roles: string[]): Observable<any[]> {
    const headers = new Headers({ 'Content-Type': 'application/json' });
    const options = new RequestOptions({ headers: headers });
    const resource: any = { userId, roles };
    return this.http
      .post(
        `${environment.apiEndpoint}/api/teacher/updateRoles` +
        this.getAccessToken(),
        resource,
        options
      )
      .pipe(
        map((res: Response) => {
          return res.json();
        }),
        catchError((error: Response) => {
          console.log(error);
          throw error.json().error || 'Server error';
        })
      );
  }*/
  /*updatePassword(userId: string, password: string): Observable<any[]> {
    const headers = new Headers({ 'Content-Type': 'application/json' });
    const options = new RequestOptions({ headers: headers });
    const resource: any = { userId, password };
    return this.http
      .post(
        `${environment.apiEndpoint}/api/teacher/updatePassword` +
        this.getAccessToken(),
        resource,
        options
      )
      .pipe(
        map((res: Response) => {
          return res.json();
        }),
        catchError((error: Response) => {
          console.log(error);
          throw error.json().error || 'Server error';
        })
      );
  }*/
  /*updateYourPassword(
    userId: string,
    newPassword: string,
    oldPassword: string
  ): Observable<any[]> {
    const headers = new Headers({ 'Content-Type': 'application/json' });
    const options = new RequestOptions({ headers: headers });
    const resource: any = { newPassword, oldPassword };
    return this.http
      .post(
        `${environment.apiEndpoint}/api/teacher/change-password` +
        this.getAccessToken(),
        resource,
        options
      )
      .pipe(
        map((res: Response) => {
          return res.json();
        }),
        catchError((error: Response) => {
          console.log(error);
          throw error.json().error || 'Server error';
        })
      );
  }*/
}
